import { useEffect, useState } from "react";
import Index from "../../Index";

import PageIndex from "../../PageIndex";
import { loginAdmin } from "../../../redux/slices/AdminService";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitForm = (values, action) => {
    try {
      setIsDisabled(true);
      dispatch(loginAdmin(values)).then((res) => {
        action.resetForm();
        setIsDisabled(false);
        if (res?.payload?.status === 200) {
          if (res?.payload?.data?.token) {
            localStorage.setItem("token", res?.payload?.data?.token);
            const token = res?.payload?.data?.token;
            PageIndex.DataService.defaults.headers.common.auth = token;
          }
          console.log(res, "res");
          navigate("/admin/dashboard");
        }
      });
    } catch (error) {
      setIsDisabled(false);
    }
  };

  return (
    // <Index.LoadingOverlay spinner text="Loading..." active={loading}>

    <Index.Box className="admin-login-main-flex">
      {/* <Index.Box className="admin-login-left-main">
        <Index.Box className="login-left-bg">
          <Index.Box className="auth-logo-box">
            <img src={Index.Svg.brand} className="auth-logo" />
          </Index.Box>
        </Index.Box>
      </Index.Box> */}
      <Index.Box className="admin-login-right-main">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="auth-logo-box">
              <img src={Index.Svg.brand} className="auth-logo" />
            </Index.Box>
            <PageIndex.Formik
              validationSchema={PageIndex.adminLoginSchema}
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,

                touched,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="admin-login-inner">
                    <Index.Typography
                      component="h2"
                      variant="h2"
                      className="admin-wel-text"
                    >
                      Login!
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="admin-sign-para common-para"
                    >
                      Please enter your credentials to sign in!
                    </Index.Typography>
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Email
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          placeholder="Enter the email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // onFocus={handleFocus}
                          helperText={touched.email && errors.email}
                          error={Boolean(errors.email && touched.email)}
                          fullWidth
                          name="email"
                          id="fullWidth"
                          className="form-control"
                          type="text"
                          autocomplete="off"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Password
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          size="small"
                          name="password"
                          placeholder="Enter the password"
                          className="form-control input-with-radius password-form-control"
                          type={showPassword ? "text" : "password"}
                          // label="Password"
                          variant="outlined"
                          autoComplete="off"
                          inputProps={{
                            className: "input_props",
                          }}
                          InputLabelProps={{ className: "add-formlabel" }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                          onBlur={handleBlur}
                          value={values.password}
                          onChange={handleChange}
                          helperText={touched.password && errors.password}
                          error={Boolean(errors.password && touched.password)}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  className="passwrd-eye"
                                  aria-label="toggle password visibility"
                                  onClick={(e) => handleClickShowPassword(e)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            ),
                          }}
                        />
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="primary-btn-main">
                      <Index.Button
                        type="submit"
                        className="primary-btn"
                        disabled={isDisabled}
                      >
                        Sign In
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* <Index.Box className="vdo-frame">
        <video
          className="presale-video"
          controls="false"
          autoPlay="true"
          muted
          loop
          playsinline=""
          data-wf-ignore="true"
          data-object-fit="cover"
        >
          <source src={PageIndex.Video.videoTwo} type="video/mp4" alt="banner video" />
        </video>
      </Index.Box> */}
    </Index.Box>
  );
}
