import React, { useEffect, useState } from 'react';
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

function ComingSoon() {
  return (
    <>
        <Index.Box className="comingsoon-wrape">
            <img
              src={Index.Png.logo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Box className="inner">
                <Index.Box className="globe"></Index.Box>
                <Index.Box className="globe-shadow"></Index.Box>
                <Index.Typography component="h2">Coming Soon</Index.Typography>
                <Index.Box className="button-wrape">
                    <Index.Link to="/">
                        <img src={PageIndex.Png.iosappstore} alt="" />
                    </Index.Link>
                    <Index.Link to="/">
                        <img src={PageIndex.Png.androidappstore} alt="" />
                    </Index.Link>
                </Index.Box>
                {/* <Index.Typography component="p">Lorem Ipsum</Index.Typography> */}
            </Index.Box>
        </Index.Box>
    </>
  )
}

export default ComingSoon