import { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { compainMasterValidationSchema } from "../../../../validations/Schema";
import PageIndex from "../../../PageIndex";
function AddCampaign() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { id } = Index.useParams();
  const [loading, setLoading] = useState(false);
  const [getReferral, setReferral] = useState('');
  const [singleCampaign, setSingleCampaign] = useState();

  let initialValues = {};
  if (singleCampaign) {
    initialValues = {
      name: singleCampaign ? singleCampaign?.name : "",
      description: singleCampaign ? singleCampaign?.description : "",
      email: singleCampaign ? singleCampaign?.email : "",
      mobileNumber: singleCampaign ? singleCampaign?.mobileNumber : "",
      referralCode: singleCampaign ? singleCampaign?.referralCode : "",
    };
  } else {
    initialValues = {
      name: "",
      email:"",
      description: "",
      mobileNumber: "",
      referralCode: getReferral,
    };
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    const urlencoded = new URLSearchParams();
    if (singleCampaign) {
      urlencoded.append("id", singleCampaign?._id);
    }
    for (let keys in values) {
      urlencoded.append(keys, values[keys]);
    }

    PageIndex.doPost(PageIndex.Api.ADD_EDIT_COMPAIN,urlencoded).then((res) => {
      console.log("res",res);
      if (res?.status === 200 || res?.status === 201) {
        navigate("/admin/campaign-master");
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
      }
    });
  };

  const BackBtn = () => {
    navigate("/admin/campaign-master");
  };


  const fetchSingleCompainList = (id) => {
    setLoading(true);
    try {
    PageIndex.doGetParams(`${PageIndex.Api.GET_SPECIFIC_LIST}`,`/${id}`).then((res) => {
        if (res?.status === 200) {
          setSingleCampaign(res?.data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setSingleCampaign();
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      fetchSingleCompainList(id);
    }
  }, [id]);

  useEffect(() => {
    setReferral(Index.generateReferralCode())
  }, []);


  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          {loading && id ? (
            <Index.Box className="">
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
                <Index.Grid item xs={2} sm={2} md={2}>
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.Grid>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
              </Index.Grid>
            </Index.Box>
          ) : (
            <>
              <Index.Box className="presale-wraper">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex">
                    <Index.Box className="title-main mb-10">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        {singleCampaign ? "Edit" : "Add"} Campaign
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="common-button blue-button save-btn primary-btn-main">
                      <Index.Button
                        variant="contained"
                        type="submit"
                        className="Particular_model_submit ml-0 primary-btn"
                        onClick={BackBtn}
                      >
                        Back
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="form-wrape">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={compainMasterValidationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        {/* <Index.Box className="add-task-form-main"> */}
                        <Index.Box className="crtl-wrape">
                          <Index.Box className="inner-pad">
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12} sm={6} md={6}>
                                  <Index.Box className="inner-pad2">
                                    <Index.Typography className="admin-form-lable" component="p">
                                      Name <span className="astrick-sing">*</span>
                                    </Index.Typography>
                                    <Index.TextField
                                        hiddenLabel
                                        id="filled-hidden-label-normal"
                                        placeholder="Name"
                                        className="input-fild"
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.name}
                                        error={Boolean(
                                          errors.name ? touched.name : undefined
                                        )}
                                        helperText={
                                          touched.name ? errors.name : undefined
                                        }
                                        variant="standard"
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                      ></Index.TextField>
                                  </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={6}>
                                  <Index.Box className="inner-pad2">
                                    <Index.Typography className="admin-form-lable" component="p">
                                      Email <span className="astrick-sing">*</span>
                                    </Index.Typography>
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder="Email"
                                      className="input-fild"
                                      name="email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.email}
                                      error={Boolean(
                                        errors.email ? touched.email : undefined
                                      )}
                                      helperText={
                                        touched.email ? errors.email : undefined
                                      }
                                      variant="standard"
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                    ></Index.TextField>
                                  </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={12} md={12}>
                                  <Index.Box className="inner-pad2">
                                    <Index.Typography className="admin-form-lable" component="p">
                                    Description <span className="astrick-sing">*</span>
                                  </Index.Typography>
                                    <Index.TextareaAutosize
                                      hiddenLabel
                                      minRows={4}
                                      id="filled-hidden-label-normal"
                                      className={touched?.description && errors?.description ?  "input-fild Mui-error" : "input-fild"}
                                      placeholder="Description"
                                      name="description"
                                      value={values.description}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      variant="standard"
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                    ></Index.TextareaAutosize>
                                  
                                    {touched?.description && errors?.description && (
                                      <Index.FormHelperText error>
                                        {errors?.description}
                                      </Index.FormHelperText>
                                    )}
                                  </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={6}>
                                  <Index.Box className="inner-pad2">
                                    <Index.Typography className="admin-form-lable" component="p">
                                    Mobile Number <span className="astrick-sing">*</span>
                                  </Index.Typography>
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder="Mobile Number"
                                      className="input-fild"
                                      name="mobileNumber"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.mobileNumber}
                                      error={Boolean(
                                        errors.mobileNumber ? touched.mobileNumber : undefined
                                      )}
                                      helperText={
                                        touched.mobileNumber ? errors.mobileNumber : undefined
                                      }
                                      variant="standard"
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                    ></Index.TextField>
                                  </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={6}>
                                  <Index.Box className="inner-pad2">
                                    <Index.Typography className="mint-btn" component="p">
                                    Referral Code
                                  </Index.Typography>
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder="Referral Code"
                                      className="input-fild"
                                      name="referralCode"
                                      value={values?.referralCode}
                                      disabled="true"
                                      variant="standard"
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                    />
                                  </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={2} md={2}>
                                <Index.Box className="card-btn-flex">
                                <Index.Box className="primary-btn-main user-submit-btn">
                                  <Index.Button
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="primary-btn"
                                  >
                                    {singleCampaign ? "Update" : "Submit"}
                                  </Index.Button>
                                </Index.Box>
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>

                            
                              
                          </Index.Box>
                        </Index.Box>
                        {/* </Index.Box> */}
                      </form>
                    )}
                  </Formik>
                </Index.Box>
              </Index.Box>
            </>
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddCampaign;
