import React, { useEffect, useState, useRef } from 'react';
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import ExcelExportHelper from "../../../../component/common/ExcelExportHelper";
import QrCodeGenerate from "../../../../component/common/QrCodeGenerate";

function CampaignList() {
    const navigate = Index.useNavigate();
    const [loading, setLoading] = useState(false);
    const [campaignList, setCompainLlist] = useState([]);
    const [filterData, setFilterData] = useState([]);
    //Referral Link 
    const [referralLink, setReferralLink] = useState("");
    const downloadRef = useRef(null);
    // State for pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    // Delete record state
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [rowId, setRowId] = useState("");


    //open qr code model.
    const [open, setOpen] = useState(false);

    //Delete Model
    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };
    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setRowId("");
    };

    //Handle campaign edit
    const handleEdit = (item) => {
        navigate(`/admin/campaign-master/edit/${item?._id}`);
    };

    //Handle campaign view
    const handleView = (item) => {
        navigate(`/admin/campaign-master/view/${item?._id}`, {
            state: { name: item?.name },
        });
    };


    // page load get list of compaign list
    const getCompainList = () => {
        setLoading(true);
        try {
            PageIndex.doGet(PageIndex.Api.GET_COMPAIN_LIST).then((res) => {
                if (res?.status === 200) {
                    let compainListModify = res?.data?.data?.map((item) => {
                        return {
                            _id: item?._id,
                            name: item.name || "-",
                            email: item.email || "-",
                            mobileNumber: item.mobileNumber || "-",
                            referralCode: item.referralCode || "-",
                            description:
                                item.description.length > 10
                                    ? `${item.description.slice(0, 5)}...${item.description.slice(
                                        -5
                                    )}`
                                    : item.description
                                        ? item.description
                                        : "-",
                            createdAt: item.createdAt
                                ? Index.moment(item.createdAt)
                                    .utc()
                                    .format("DD-MM-YYYY hh:mm A")
                                : "-",
                        };
                    });

                    setCompainLlist(compainListModify);
                    setFilterData(compainListModify);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            });
        } catch (error) { }
    };

    //page laod getCompainList data.
    useEffect(() => {
        getCompainList();
    }, []);

    const QRCODEBASEURL = "https://plutope.page.link/u9DC?referral"

    //copy of referral link.
    const handleCopy = (data, type) => {
        Index.copyTextToClipboard(`${QRCODEBASEURL}=${data}`);
        Index.toast.success("Referral Copied");
    };
    // handle Referral QRCode.
    const handleQrScan = (data, type) => {
        setReferralLink(`${QRCODEBASEURL}=${data}`);
        handleOpen();
    };

    // Handle qr Modal open
    const handleOpen = () => {
        setOpen(true);
    };
    // Handle qr Modal close
    const handleClose = () => {
        setOpen(false);
    };

    //Qrcode style
    const Qrstyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
    };

    //Called downloadQrCode function which was child componets.
    const downloadQr = () => {
        if (downloadRef?.current) {
            downloadRef?.current?.downloadQrCode();
        } else {
            console.error('Ref is not attached properly.');
        }
    };

    const handleSearch = (e) => {
        let serachValue = e.target.value.toLowerCase();
        setFilterData(
            campaignList.filter((item) => {
                return (
                    item?.name?.toLowerCase()?.includes(serachValue) ||
                    item?.email?.toLowerCase()?.includes(serachValue) ||
                    item?.mobileNumber?.toString()?.includes(serachValue) ||
                    item?.referralCode?.toLowerCase()?.includes(serachValue)
                );
            })
        );
        setPage(0);
    };

    //export csv data 
    const sheetData = filterData.map(
        ({ _id, isActive, updatedAt, __v, ...rest }) => rest
    )

    return (
        <>
            <Index.DynamicTitle title="presale-wraper" />
            <Index.Box className="presale-wraper">
                <Index.Box className="res-cus-table">
                    <Index.Box className="offering-cus-table">
                        <Index.Box className="offering-table-head tryed">
                            <Index.Box className="title-main">
                                <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                >
                                    Campaign List
                                </Index.Typography>
                            </Index.Box>
                            {/* <Index.Box className="admin-search-main cus-search">
                <Index.Box className="admin-search-box">
                  <Index.Box className="admin-form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="admin-form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />

                    <img
                    src={Index.Svg.greysearch}
                    className="admin-search-grey-img"
                    alt="search grey img"
                  ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box> */}

                            {/* <Index.Box className="filer-bulker">
                {filterData?.length && (
                  <>
                    <Index.Box className="common-button grey-button">
                      <ExcelExportHelper
                        data={sheetData}
                        loading={loading}
                        sheetName={"CompainMaster"}
                      />
                    </Index.Box>
                  </>
                )}
              </Index.Box> */}
                            <Index.Box className="common-button blue-button res-blue-button">
                                <Index.RouteLink
                                    to="/admin/campaign-master/add"
                                    className="no-text-decoration primary-btn-main"
                                >
                                    {" "}
                                    <Index.Button
                                        variant="contained"
                                        disableRipple
                                        className="primary-btn"
                                    >
                                        Add Campaign
                                    </Index.Button>
                                </Index.RouteLink>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>

                    <Index.Box className="border-round-table">
                        <Index.TableContainer
                            component={Index.Paper}
                            className="table-container"
                        >
                            <Index.Table
                                aria-label="simple table"
                                className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
                            >
                                <Index.TableHead className="gradient-bg">
                                    <Index.TableRow>
                                        <Index.TableCell align="left">Name</Index.TableCell>
                                        <Index.TableCell align="left">Email Id</Index.TableCell>
                                        <Index.TableCell align="left">Mobile No</Index.TableCell>
                                        <Index.TableCell align="left">Referral Code </Index.TableCell>
                                        <Index.TableCell align="left">Qr Code</Index.TableCell>
                                        <Index.TableCell align="left"> Date </Index.TableCell>
                                        <Index.TableCell align="left">Action</Index.TableCell>
                                    </Index.TableRow>
                                </Index.TableHead>
                                {loading ? (
                                    <Index.TableBody>
                                        <Index.TableRow>
                                            <Index.TableCell colSpan={14} align="center">
                                                <Index.CircularProgress color="secondary" size={20} />
                                            </Index.TableCell>
                                        </Index.TableRow>
                                    </Index.TableBody>
                                ) : (
                                    <Index.TableBody>
                                        {filterData?.length ? (
                                            filterData
                                                ?.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                .map((item, index) => (
                                                    <Index.TableRow key={item?._id}>
                                                        <Index.TableCell>{item?.name}</Index.TableCell>
                                                        <Index.TableCell>{item?.email}</Index.TableCell>
                                                        <Index.TableCell>
                                                            {item?.mobileNumber}
                                                        </Index.TableCell>
                                                        <Index.TableCell>
                                                            <Index.Tooltip title="Copy">
                                                                <Index.Typography
                                                                    className="set-hover-text"
                                                                    variant="p"
                                                                    onClick={() =>
                                                                        handleCopy(
                                                                            item?.referralCode,
                                                                            "Referral_code"
                                                                        )
                                                                    }
                                                                >
                                                                    {item?.referralCode}
                                                                </Index.Typography>
                                                            </Index.Tooltip>
                                                        </Index.TableCell>


                                                        <Index.TableCell>
                                                            <Index.Link class="text-decoration res-copt-text">
                                                                <Index.Box class="primary-border-btn-main copy-btn copy-btn-set">
                                                                    <Index.IconButton
                                                                        onClick={() => {
                                                                            handleQrScan(
                                                                                item?.referralCode,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Index.QrCodeScannerIcon />
                                                                    </Index.IconButton>

                                                                </Index.Box>
                                                            </Index.Link>


                                                        </Index.TableCell>

                                                        {/* <Index.TableCell>
                            <Index.Tooltip
                              title={item.description}
                              arrow
                              placement="top"
                            >
                              <span>{item.description}</span>
                            </Index.Tooltip>

                          </Index.TableCell> */}
                                                        <Index.TableCell>{item?.createdAt}</Index.TableCell>

                                                        {/* <Index.TableCell>
                            <Index.IconButton>
                              <Index.EditIcon
                                className="edit-icon-set"
                                onClick={() => handleEdit(item)}
                              />
                            </Index.IconButton>
                          </Index.TableCell> */}
                                                        <Index.TableCell>
                                                            <Index.Button
                                                                className="admin-table-data-btn"
                                                                onClick={() => handleView(item)}
                                                            >
                                                                <img
                                                                    src={Index.Svg.blueeye}
                                                                    className="admin-icon"
                                                                    alt="View"
                                                                ></img>
                                                            </Index.Button>
                                                        </Index.TableCell>
                                                    </Index.TableRow>
                                                ))
                                        ) : (
                                            <Index.TableRow>
                                                <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="no-data-in-list"
                                                    colSpan={15}
                                                    align="center"
                                                    style={{ color: "black" }}
                                                >
                                                    No data available
                                                </Index.TableCell>
                                            </Index.TableRow>
                                        )}
                                    </Index.TableBody>
                                )}
                            </Index.Table>
                        </Index.TableContainer>
                    </Index.Box>
                    <Index.Box className="pagination-main">
                        <Index.TablePagination
                            component="div"
                            page={page}
                            count={filterData?.length}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[15, 25, 50]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelDisplayedRows={(page) =>
                                `Records ${page.from} to ${page.to === -1 ? page.count : page.to
                                } of ${page.count}`
                            }
                        />
                    </Index.Box>
                </Index.Box>
            </Index.Box>

            {/* </Index.Box > */}

            {/* <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      /> */}

            {/* //Referral Qr Code  */}
            <Index.Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="admin-modal"
            >
                <Index.Box
                    sx={Qrstyle}
                    className="admin-delete-modal-inner-main admin-modal-inner qr-scanner-modal-inner"
                >
                    <Index.Box className="admin-modal-header">
                        <Index.Typography
                            id="modal-modal-title"
                            className="admin-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            QR CODE
                        </Index.Typography>
                        <Index.Button className="modal-close-btn" onClick={handleClose}>
                            <img
                                src={PageIndex.Svg.closeblack}
                                className="admin-modal-close-icon"
                                alt="Close"
                            />
                        </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                        <QrCodeGenerate ref={downloadRef} referralLink={referralLink} />
                    </Index.Box>
                    <Index.Box className="modal-footer">
                        <Index.Box className="admin-modal-user-btn-flex">
                            <Index.Box className="btn-main-primary">
                                <Index.Button className="btn-primary-sub" onClick={downloadQr} >
                                    Download
                                </Index.Button>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
        </>
    );
}

export default CampaignList;
