import React from "react";
import { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
export default function ViewReferral() {
    const { id } = Index.useParams();
    const navigate = Index.useNavigate();
    const {state} = Index.useLocation();
    const [loading, setLoading] = useState(false);
    const [referralDetail, setReferrallDetails] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [TotalUser, setTotalUser] = useState();
    const [TotalTodayReFerralUser, setTotalTodayReFerralUser] = useState();


    // Delete record state
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [rowId, setRowId] = useState("");

    // State for pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };


    const fetchReferralDetails = (id) => {
        try {
            PageIndex.doGetParams(`${PageIndex.Api.GET_SPECIFIC_LIST}`, `/${id}`).then((res) => {
                if (res?.status === 200) {
                    let referrallListModify = res?.data?.ReferralDetails?.ReferralDetails?.map((item) => {
                        return {
                            _id: item?._id,
                            deviceId: item?.deviceId ? item?.deviceId : '-',
                            deviceType: item?.deviceType == 0 ? 'Android' : item?.deviceType == 1 ? 'IOS' : "-" ?? '-',
                            walletAddress: item?.walletAddress ? item?.walletAddress : '-',
                            referral_code: item?.referral_code ? item?.referral_code : '-',
                            createdAt: item.createdAt ? Index.moment(item.createdAt).utcOffset('+05:30').format("DD-MM-YYYY hh:mm A") : "-",
                        };
                    });
                    setReferrallDetails(referrallListModify);
                    setFilterData(referrallListModify);
                    setTotalUser(res?.data?.ReferralDetails?.totalReferralUser);
                    setTotalTodayReFerralUser(res?.data?.ReferralDetails?.totalTodayReferralUser);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            });
        } catch (error) { }
    };

    useEffect(() => {
        if (id) {
            fetchReferralDetails(id);
        }
    }, [id]);

    return (
        <>
            <Index.DynamicTitle title="presale-wraper" />
            <Index.Box className="presale-wraper">
                <Index.Box className="res-cus-table">
                    <Index.Box className="offering-cus-table">
                        <Index.Box className="offering-table-head tryed">
                            <Index.Box className="title-main">
                                <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                >
                                    {state?.name} Referral List
                                </Index.Typography>
                            </Index.Box>

                            <Index.Box className="common-button blue-button save-btn primary-btn-main">
                                <Index.Button
                                    variant="contained"
                                    type="submit"
                                    className="Particular_model_submit ml-0 primary-btn"
                                    onClick={() => navigate("/admin/campaign-master")}
                                >
                                    Back
                                </Index.Button>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    {/* First Row */}
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                            display="grid"
                            className="display-row-dashboard"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                            {/* Total Users */}
                            <Index.Box
                                gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 3",
                                }}
                                className="grid-column active"
                            >
                                <Index.Box className="admin-dashboard-box">
                                    <Index.Box className="admin-dashboard-inner-box">
                                        <Index.Box className="admin-dash-left">
                                            <Index.Typography
                                                className="admin-dash-text"
                                                variant="p"
                                                component="p"
                                            >
                                                Total Wallets{" "}
                                            </Index.Typography>
                                            <Index.Typography
                                                className="admin-dash-price"
                                                variant="h1"
                                                component="h1"
                                            >
                                                <Index.CountUp
                                                            delay={0.4}
                                                            end={TotalUser}
                                                            duration={0.7}
                                                        />
                                            </Index.Typography>
                                        </Index.Box>
                                        <Index.Box className="admin-dash-right">
                                            <img
                                                src={Index.Svg.walletBlack}
                                                className="dash-icons"
                                                alt="dashboard icon"
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            {/* Total Wallets */}
                            <Index.Box
                                gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 3",
                                }}
                                className="grid-column total"
                            >
                                <Index.Box className="admin-dashboard-box">
                                    <Index.Box className="admin-dashboard-inner-box">
                                        <Index.Box className="admin-dash-left">
                                            <Index.Typography
                                                className="admin-dash-text"
                                                variant="p"
                                                component="p"
                                            >
                                                Today Created Walllet
                                            </Index.Typography>
                                            <Index.Typography
                                                className="admin-dash-price"
                                                variant="h1"
                                                component="h1"
                                            >
                                                <Index.CountUp
                                                            delay={0.4}
                                                            end={TotalTodayReFerralUser}
                                                            duration={0.7}
                                                        />
                                            </Index.Typography>
                                        </Index.Box>
                                        <Index.Box className="admin-dash-right">
                                            <img
                                                src={Index.Svg.wallet}
                                                className="dash-icons"
                                                alt="dashboard icon"
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>

                    <Index.Box className="border-round-table">
                        <Index.TableContainer
                            component={Index.Paper}
                            className="table-container"
                        >
                            <Index.Table
                                aria-label="simple table"
                                className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
                            >
                                <Index.TableHead className="gradient-bg">
                                    <Index.TableRow>
                                        <Index.TableCell align="left">Device Id</Index.TableCell>
                                        <Index.TableCell align="left">Device Type</Index.TableCell>
                                        <Index.TableCell align="left">Wallet Address</Index.TableCell>
                                        <Index.TableCell align="left"> Date </Index.TableCell>
                                    </Index.TableRow>
                                </Index.TableHead>
                                {loading ? (
                                    <Index.TableBody>
                                        <Index.TableRow>
                                            <Index.TableCell colSpan={14} align="center">
                                                <Index.CircularProgress color="secondary" size={20} />
                                            </Index.TableCell>
                                        </Index.TableRow>
                                    </Index.TableBody>
                                ) : (
                                    <Index.TableBody>
                                        {filterData?.length ? (
                                            filterData
                                                ?.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                ).map((item, index) => (
                                                    <Index.TableRow key={item?._id}>
                                                        <Index.TableCell>{item.deviceId}</Index.TableCell>
                                                        <Index.TableCell>{item.deviceType}</Index.TableCell>
                                                        <Index.TableCell>{item.walletAddress}</Index.TableCell>
                                                        <Index.TableCell>{item.createdAt}</Index.TableCell>
                                                    </Index.TableRow>
                                                ))
                                        ) : (
                                            <Index.TableRow>
                                                <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="no-data-in-list"
                                                    colSpan={15}
                                                    align="center"
                                                    style={{color:'black'}}
                                                >
                                                    No data available
                                                </Index.TableCell>
                                            </Index.TableRow>
                                        )}
                                    </Index.TableBody>
                                )}
                            </Index.Table>
                        </Index.TableContainer>
                    </Index.Box>
                    <Index.Box className="pagination-main">
                        <Index.TablePagination
                            component="div"
                            page={page}
                            count={filterData?.length}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[15, 25, 50]}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelDisplayedRows={(page) =>
                                `Records ${page.from} to ${page.to === -1 ? page.count : page.to
                                } of ${page.count}`
                            }
                        />
                    </Index.Box>
                </Index.Box>

            </Index.Box>
            {/* </Index.Box > */}

            {/* <Index.DeleteModal
          deleteOpen={deleteOpen}
          handleDeleteRecord={handleDeleteRecord}
          handleDeleteClose={handleDeleteClose}
        /> */}
        </>
    );
}
