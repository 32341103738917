import React from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import Home from "../container/admin/pages/home/Home";
import Login from "../container/admin/auth/Login";
import ComingSoon from "../container/admin/pages/comingSoon/ComingSoon";
import PrivateRoute from "./PrivateRoute";
import PresaleActivity from "../container/admin/pages/home/PresaleActivity";
import AddCampaign from "../container/admin/pages/campaign/Add";
import CompainList from "../container/admin/pages/campaign/List";
import ViewReferral from "../container/admin/pages/campaign/View";

const Routes = () => {
  return (
    <BrowserRouter>
      <Routess>
        <Route path="/admin/login" element={<Login />} />
        <Route path="/" element={<ComingSoon />} />

        <Route element={<PrivateRoute />}>
          <Route path="/admin" element={<Dashboard />}>
            <Route path="dashboard" element={<Home />} />
            <Route path="presaleactivity" element={<PresaleActivity />} />
            <Route path="campaign-master" element={<CompainList />} />
            <Route path="campaign-master/add" element={<AddCampaign />} />
            <Route path="campaign-master/edit/:id" element={<AddCampaign />} />
            <Route path="campaign-master/view/:id" element={<ViewReferral />} />
          </Route>
        </Route>
      </Routess>
    </BrowserRouter>
  );
};

export default Routes;
