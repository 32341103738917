import React, { forwardRef, useImperativeHandle } from 'react';
import { QRCode } from 'react-qrcode-logo';
import Index from '../Index'; 

const QrCodeGenerate = forwardRef((props, ref) => {
  let { referralLink } = props;

  useImperativeHandle(ref, () => ({
    downloadQrCode() {
      const canvas = document.getElementById(referralLink);
      if (canvas) {
        const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${referralLink.split('=')[1]}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  }));

  return (
    <Index.Box className="qr-main">
      <QRCode
        value={referralLink}
        size={250}
        logoImage={Index.Png.plutopeIcon}
        logoHeight={40}
        logoWidth={40}
        logoOpacity={1}
        enableCORS={true}
        qrStyle="squares"
        eyeRadius={10}
        id={referralLink}
      />
    </Index.Box>
  );
});

export default QrCodeGenerate;
