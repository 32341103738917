import * as Yup from "yup";

const digitsOnly = (value) =>
  /^\d*(?:\.\d*)?$/.test(value) || value?.length === 0;


// const charSpace = (value) =>
//   /^[^-\s][a-zA-Z\s]+$/.test(value) || value.length === 0;

export const adminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),

  password: Yup.string().required("Please enter your password"),
});


export const compainMasterValidationSchema = Yup.object().shape({
    name: Yup.string()
    .min(2, "Name is too short, min 2 characters")
    .max(16, "Name is too long , max 16 characters")
    .required("Name is required"),
    mobileNumber: Yup.string()
    .min(10, "Please Enter valid Mobile Number")
    .max(10, "Please Enter valid Mobile Number")
    .test("inputEntry", "Mobile Number should have digits only", digitsOnly)
    .required("Mobile Number is required"),
    email: Yup.string()
    .email("Enter the valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("Email is required"),
  description: Yup.string()
    .min(2, "Description is too short, min 2 characters")
    .max(200, "Description is too large max 200 characters")
    .required("Description is required"),
});