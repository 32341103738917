import { createSlice } from "@reduxjs/toolkit";
import { loginAdmin } from "./AdminService";

export const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    adminData: {},
    status: "idle",
    error: null,
    isLogin: false,
    token: "",
  },
  reducers: {
    logout: (state) => {
      state.isLogin = false;
      state.adminData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLogin = true;
        state.token = action.payload?.data?.token;
        state.adminData = action.payload;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { logout } = AdminSlice.actions;

export default AdminSlice.reducer;
