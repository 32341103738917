import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  Collapse,
  Pagination,
  Stack,
  SwipeableDrawer,
  Avatar,
  AvatarGroup,
  TablePagination,
  Tooltip,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Svg from "../assets/Svg";
import Png from "../assets/Png";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Sidebar from "../component/defaultLayout/Sidebar";
import Header from "../component/defaultLayout/Header";
import CircularProgress from "@mui/material/CircularProgress";
import DynamicTitle from "../component/common/DynamicTitle";
import EditIcon from '@mui/icons-material/Edit';


import PrimaryButton from "../component/common/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation, Link as RouteLink, Navigate, Outlet } from "react-router-dom";

import moment from "moment";
import copyTextToClipboard from "copy-text-to-clipboard";
import { toast } from "react-toastify";
import {generateReferralCode } from '../component/common/CommanHealper';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import dayjs from 'dayjs';
import CountUp from "react-countup";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";


export default {
  Box,
  Link,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,

  Visibility,
  Link,
  List,
  ListItem,
  Svg,
  Png,
  Sidebar,
  Header,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,

  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  ExpandLess,
  ExpandMore,
  Collapse,
  Stack,
  Pagination,
  TablePagination,
  SwipeableDrawer,

  Grid,
  Avatar,
  AvatarGroup,
  PrimaryButton,
  CircularProgress,
  DynamicTitle,
  EditIcon,
  Tooltip,

  useDispatch, 
  useSelector,
  useNavigate,
  useParams, 
  useLocation,
  RouteLink, 
  Navigate,
  moment,
  copyTextToClipboard,
  toast,
  generateReferralCode,
  LocalizationProvider,
  DemoContainer,
  DateRangePicker,
  AdapterDayjs,
  SingleInputDateRangeField,
  dayjs,
  CountUp,
  QrCodeScannerIcon
};
